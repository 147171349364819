import { fetchAllList, fetchListByIds, fetchOne, fetchList } from 'share/services/api'
import { findById, mapByIds, sortByName } from 'share/services/utils'

const assignSectors = resource => Promise.all([
  fetchListByIds('/building_sectors', 'building_sectors', resource.buildingSectorIds).then(list => resource.$buildingSectors = list),
  fetchListByIds('/alarm_sectors', 'alarm_sectors', resource.alarmSectorIds).then(list => resource.$alarmSectors = list),
  fetchListByIds('/room_sectors', 'room_sectors', resource.roomSectorIds).then(list => resource.$roomSectors = list)
]).then(() => resource);

const assignSectorsForList = list => {
  let { bsIds, asIds, rsIds } = list.reduce((acc, resource) => {
    acc.bsIds = [...acc.bsIds, ...resource.buildingSectorIds];
    acc.asIds = [...acc.asIds, ...resource.alarmSectorIds];
    acc.rsIds = [...acc.rsIds, ...resource.roomSectorIds];
    return acc;
  }, { bsIds: [], asIds: [], rsIds: [] });
  return Promise.all([
    fetchListByIds('/building_sectors', 'building_sectors', bsIds),
    fetchListByIds('/alarm_sectors', 'alarm_sectors', asIds),
    fetchListByIds('/room_sectors', 'room_sectors', rsIds)
  ]).then(([buildingSectors, alarmSectors, roomSectors]) => {
    list.forEach((workflow) => {
      workflow.$buildingSectors = mapByIds(buildingSectors, workflow.buildingSectorIds);
      workflow.$alarmSectors = mapByIds(alarmSectors, workflow.alarmSectorIds);
      workflow.$roomSectors = mapByIds(roomSectors, workflow.roomSectorIds);
    });
  });
}

const getTargetList = (resources, items) => items.reduce((acc, item) => {
  let resource = findById(resources, item.id, null);
  if(resource) acc.push({ $resource: resource, ...item });
  return acc;
}, []);

const skipDeleted = r => !r.isDeleted;

const getAlarmLevelList = filter =>
  fetchList('/alarm_types/alarm_levels/after/0', 'alarm_levels').
    then(list => filter ? list.filter(skipDeleted) : list);

const mapLevels = (alarmTypes, alarmLevels) => {
  alarmTypes.forEach(a => a.$alarmLevels = alarmLevels.filter(l => a.id === l.alarmTypeId));
  return alarmTypes;
}

const getAlarmTypeWizardList = () => Promise.all([
  fetchAllList('alarm_types'),
  getAlarmLevelList(true)
]).then(([alarmTypes, alarmLevels]) => mapLevels(alarmTypes, alarmLevels))

const getAlarmTypeWithLevels = id => Promise.all([
  fetchOne(`/alarm_types/${id}`, 'alarm_types', 'alarm_type'),
  fetchList(`/alarm_types/${id}/alarm_levels`, 'alarm_levels')
]).then(([alarmType, alarmLevels]) => {
  alarmType.$alarmLevels = alarmLevels;
  return alarmType;
})

const WorkflowForm = {
  getTriggers: () => getAlarmTypeWizardList().then((alarmTypes) => ({ alarmTypes })),
  assignTriggers(workflow) {
    return assignSectors(workflow).then(() => {
      switch(workflow.kind) {
        case 'building':
          workflow.$sectors = workflow.$buildingSectors;
          break;
        case 'alarm':
          workflow.$sectors = workflow.$alarmSectors;
          break;
        case 'room':
          workflow.$sectors = workflow.$roomSectors;
          break;
      }
    });
  },
  getTargets: () => Promise.all([
      fetchAllList('users'),
      fetchAllList('user_groups'),
      fetchAllList('roles', { path: '/dictionaries/roles' }),
      fetchAllList('oncall_groups')
    ]).then(([users, userGroups, userRoles, oncallGroups]) => ({ users, userGroups, userRoles, oncallGroups })),
  assignTargets(workflow, dict) {
    workflow.$users = getTargetList(dict.users, workflow.users);
    workflow.$userGroups = getTargetList(dict.userGroups, workflow.userGroups);
    workflow.$userRoles = getTargetList(dict.userRoles, workflow.userRoles);
    workflow.$oncallGroups = getTargetList(dict.oncallGroups, workflow.oncallGroups);
  },
  getNotifications: () => Promise.all([
    fetchAllList('contact_lists'),
    fetchAllList('task_lists'),
  ]).then(([contactLists, taskLists]) => ({ contactLists: contactLists.sort(sortByName), taskLists })),
  assignNotifications(workflow, dict) {
    workflow.$contactLists = mapByIds(dict.contactLists, workflow.contactListIds);
    workflow.$taskLists = mapByIds(dict.taskLists, workflow.taskListIds);
  },

  getDict() {
    return Promise.all([
      this.getTriggers(),
      this.getTargets(),
      this.getNotifications()
    ]).then(data => data.reduce((result, part) => Object.assign(result, part), {}))
  },

  assign(workflow, dict) {
    let promises = [];
    promises.push(this.assignTriggers(workflow));
    this.assignTargets(workflow, dict);
    this.assignNotifications(workflow, dict);

    workflow.$alarmType = findById(dict.alarmTypes, workflow.alarmTypeId, null);
    if(workflow.$alarmType) {
      workflow.$alarmLevels = mapByIds(workflow.$alarmType.$alarmLevels, workflow.alarmLevelIds);
    } else {
      promises.push(getAlarmTypeWithLevels(workflow.alarmTypeId).then((alarmType) => {
        workflow.$alarmType = alarmType;
        workflow.$alarmLevels = mapByIds(alarmType.$alarmLevels, workflow.alarmLevelIds);
      }));
    }

    return Promise.all(promises);
  }
}

export default ["Resty", "AlarmType", (Resty, AlarmType) => Resty('workflows', klass => {
  klass.dashboardList = () => klass.tableList(1, { per_page: 5, order: 'recent', alarm_type_status: 1 });
  klass.tableList = (page, filters) => klass.page(page, filters).then(workflows => Promise.all([
    AlarmType.loadListBelongsTo(workflows, 'alarmType', 'alarmTypeId'),
    assignSectorsForList(workflows)
  ]).then(() => workflows));

  klass.loadFormData = (id) => {
    let promises = [WorkflowForm.getDict()];
    if (id) {
      promises.push(klass.fetchOne(id));
    }
    return Promise.all(promises).then(([dict, workflow]) => {
      if (workflow) {
        return WorkflowForm.assign(workflow, dict).then(() => [dict, workflow]);
      } else {
        return [dict];
      }
    });
  }
})]
