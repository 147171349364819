import toastr from '../services/toastr'

const ngAjax = function() {
  var provider = this;

  var redirectByXhr = function(xhr) {
    var url = xhr.getResponseHeader("Location");
    if(url) window.location.href = url;
  }

  var reloadPage = function() {
    window.location.reload(true);
  }

  provider.onError = function(xhr, element, callback) {
    callback(xhr, element);
  }

  provider.onSuccess = function(xhr, element, callback) {
    callback(xhr, element);
  }

  provider.$get = function() {
    return {
      onErrorResponse: function(xhr, callback) {
        if(xhr.status == 401) {
          redirectByXhr(xhr)
        } else {
          callback();
        }
      },
      onError: function(xhr, element, callback) {
        this.onErrorResponse(xhr, function() {
          provider.onError(xhr, element, callback);
        })
      },
      onSuccess: function(xhr, element, callback) {
        provider.onSuccess(xhr, element, callback);
      },
      onSuccessCallback: function(expression, scope) {
        if(expression === 'true') {
          return function(xhr) { redirectByXhr(xhr); }
        } else if(expression === 'alert') {
          return function(xhr, element) { alert(element.attr('ng-ajax-success-message')); }
        } else if(expression === 'reload') {
          return reloadPage;
        } else {
          return function(xhr, element) {
            var response = xhr.responseJSON || {};
            scope.$eval(expression, { $element: element, $response: response });
            scope.$apply();
          }
        }
      }
    }
  }
}

const onErrorCallback = (xhr, form) => {
  let errors = (xhr.responseJSON || {}).errors || {};
  $.inlineErrorsUpdate(form, errors);
  form.trigger("ajax:formErrors", [xhr.responseJSON]);
}

const ngAjaxForm = ['utils', 'ngAjax', (utils, ngAjax) => {
  $("body").on("ajax:beforeSend", "[ng-ajax-form]", function(_, xhr, settings) {
    settings["dataType"] = "json";
    $(this).inlineErrors("reset");
    utils.startLoadingBar();
    xhr.complete(() => utils.completeLoadingBar());
  }).on("ajax:error", "[ng-ajax-form]", function(a, xhr) {
    ngAjax.onError(xhr, $(this), onErrorCallback);
    window.scrollToTop();
  }).on('eva:uploadStarted ajax:beforeSend', "[ng-ajax-form]", function() {
    $(this).find(':submit, button').prop('disabled', true);
  }).on('eva:uploadCompleted ajax:complete', "[ng-ajax-form]", function() {
    $(this).find(':submit, button').prop('disabled', false);
  });

  return {
    link(scope, element, attrs) {
      element.attr('data-remote', true);
      let callback = ngAjax.onSuccessCallback(attrs.ngAjaxForm, scope);
      element.on('ajax:success', (_a, _b, _c, xhr) => ngAjax.onSuccess(xhr, element, callback));
    }
  };
}];

const ngAjaxLink = ["utils", "ngAjax", (utils, ngAjax) => {
  window.$('body').on('ajax:beforeSend', '[ng-ajax-link]', (_, xhr) => {
    utils.startLoadingBar();
    // there is no garantee that DOM will exist at ajax:complete
    // so we could not use events ajax:complete ajax:success ajax:error
    xhr.complete(() => utils.completeLoadingBar());
  });

  return {
    link(scope, element, attrs) {
      if (attrs.hasOwnProperty('disabled')) return;

      element.attr('data-remote', true);
      let callback = ngAjax.onSuccessCallback(attrs.ngAjaxLink, scope);
      element
        .on('ajax:success', (_a, _b, _c, xhr) => ngAjax.onSuccess(xhr, element, callback))
        .on('ajax:error', (_, xhr) => {
          let json = xhr.responseJSON;
          if (!json) return;

          let msg = json.error_message || (json.errors && json.errors.base[0]);
          if (msg) {
            toastr(msg, { theme: 'danger' });
          }
        });
    }
  };
}];

export default app => {
  app.provider('ngAjax', ngAjax);
  app.directive('ngAjaxForm', ngAjaxForm);
  app.directive('ngAjaxLink', ngAjaxLink);
}
