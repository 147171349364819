const getEnum = (dict, value) => {
  let item = dict.find(tuple => tuple[1] == value);
  if (item) return item[0];
}

export default () => ({
  restrict: 'A',
  scope: { value: '=evaEnum' },
  link(scope, element, attrs) {
    let dict = JSON.parse(attrs.evaEnumDict);
    element.text(getEnum(dict, scope.value));
    scope.$watch('value', (value) => element.text(getEnum(dict, value)));
  }
});
