import app from "./ng/init"
import evaEnum from "./directives/eva-enum"
import evaFilterBlock from "./directives/eva-filter-block"
import evaLocationLogo from './directives/eva-location-logo'
import "script-loader!./directives/eva-nullify"
import { ng as evaPersonRole } from "./services/person-role"
import evaSyncList from "./directives/eva-sync-list"
import { ng as evaTAttr } from "./directives/eva-t-attr"
import "script-loader!./directives/eva-view-popup"
import evaReactForm from './directives/eva-react-form'
import evaRestoreForm from './directives/eva-restore-form'
import ngAjaxSetup from "./directives/ng-ajax"
import "script-loader!./directives/ng-filter-form"
import "script-loader!./directives/ng-google-map"
import "script-loader!./directives/ng-numeric"
import ngPagination from "./directives/ng-pagination"
import ngPopup from "./directives/ng-popup"
import ngTooltip from "./directives/ng-tooltip"
import evaLocationLimits from './directives/eva-location-limits'
import { ng as ngTruncate } from "./services/truncate"
import ngVariants from "./directives/ng-variants"
import evaPhoneLink from './directives/eva-phone-link'

import Country from "./repositories/country"
import "script-loader!./repositories/lang"
import "script-loader!./repositories/role"
import User from "./repositories/user"

import { ng as evaUploader } from "./ng/services/eva-uploader"
import I18nTT from "./ng/services/i18n"
import ngResourceFilter from "./ng/services/ng-resource-filter"
import { ng as utils } from './ng/services/utils'
import viewMode from "./ng/services/view-mode"
import { buildId } from './services/utils'

app.schema.utils.buildId = buildId;

import templates from './templates'

import { I18n } from './services/i18n'

evaLocationLogo(app);
evaPersonRole(app);
evaTAttr(app);
evaUploader(app);
ngPagination(app);
ngPopup(app);
ngTruncate(app);
utils(app);
User(app);
ngAjaxSetup(app);

app.directive('ngTooltip', ngTooltip)
  .directive('ngVariants', ngVariants)
  .directive('evaEnum', evaEnum)
  .directive('evaFilterBlock', evaFilterBlock)
  .directive('evaPhoneLink', evaPhoneLink)
  .directive('evaReactForm', evaReactForm)
  .directive('evaRestoreForm', evaRestoreForm)
  .directive('evaSyncList', evaSyncList)
  .directive('i18nDynamic', () => ({
    scope: { key: '=i18nDynamic' },
    link($scope, $element) {
      $element.text(I18n.t($scope.key));
      $scope.$watch('key', key => $element.text(I18n.t(key)));
    }
  }))
  .directive('i18nStatic', () => ({
    link($scope, $element, $attrs) {
      $element.text(I18n.t($attrs.i18nStatic));
    }
  }))
  .directive('evaLocationLimits', evaLocationLimits)

  .factory('ngResourceFilter', ngResourceFilter)
  .factory('tt', I18nTT)
  .factory('ViewMode', viewMode)

  .service('License', () => console.warn('License --- DEPRECATED'))
  .service('Country', Country)

  .cacheTemplates(templates);
