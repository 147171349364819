import { PRESETS } from '../../components/file-upload'

export const ng = app => {
  let baseConfig = {
    autoUpload: true,
    headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
    removeAfterUpload: true,
    uploadPath(value) {
      if(!value) return;
      let id = (typeof value == 'object') ? value.id : value;
      return `/uploads/${encodeURIComponent(id)}`;
    },
    url: Routes.uploads()
  };

  app.factory("EvaUploader", ["FileUploader", "cfpLoadingBar", (FileUploader, loadingBar) => {
    let getElement = uploader => uploader._directives.select[0].element;

    // reset upload input to not break ajax form request
    let clearInput = function() {
      getElement(this).prop("value", null);
    }

    return (type, callback) => {
      var uploader = new FileUploader({ ...baseConfig, filters: [{ name: type, fn: PRESETS[type] }] });

      uploader.onBeforeUploadItem = function() {
        getElement(this).trigger("eva:uploadStarted");
        loadingBar.start();
      }
      uploader.onCompleteItem = function() {
        getElement(this).trigger("eva:uploadCompleted");
        loadingBar.complete();
      }

      uploader.onSuccessItem = (fi, response) => {
        callback({ id: response.file, filename: fi.file.name });
      }

      // reset upload input to not break ajax form request
      uploader.onCompleteAll = clearInput;
      uploader.onWhenAddingFileFailed = clearInput;

      return uploader;
    }
  }]);
}
