import 'script-loader!angular/angular.min'
import 'angular-file-upload'
import 'angular-loading-bar'
import pluralize from 'pluralize'
import setupSchema from './setup-schema'
import pick from 'lodash-es/pick'
import { I18n } from '../services/i18n'

const app = window.angular.module('App', ['cfp.loadingBar', 'angularFileUpload']);

app.init = () => {
  const injector = window.angular.injector(['ng', 'App']);
  app.get = name => injector.get(name);
  if(!gon.app) return;

  $(() => window.angular.bootstrap(document.body, ['App']));
};

app.run(['$rootScope', $rootScope => {
  Object.assign($rootScope, pick(window, 'Routes'));
  $rootScope.I18n = I18n;
  $rootScope.pluralize = pluralize;
  $rootScope.$closeWindow = () => window.close();
}]);

app.cacheTemplates = list => {
  app.run(['$templateCache', $cache => {
    Object.keys(list).forEach(name => $cache.put(`/ng/${name}.html`, list[name]));
  }]);
};

window.app = app;

app.schema = setupSchema(app);

export default app;
