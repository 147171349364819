let personRole = ({ position, department }) => {
  var parts = [];
  if (position) parts.push(position);
  if (department) parts.push(department);
  return parts.join(' / ');
}

export const ng = app => app.directive('evaPersonRole', () => ({
  link(scope, element, attrs) {
    let person = scope.$eval(attrs.evaPersonRole);
    element.text(personRole(person));
  }
}));

export default personRole;
