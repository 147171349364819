import { fetchListByIds } from 'share/services/api'

// a hack to compile the directive inside an ajax error
$(() => {
  $("body").on('ajax:formErrors', 'form#newEspaRoute', () => {
    let directive = $('[data-error-for="base"] eva-sectors');
    if(directive.length === 0) return;

    let content = app.get('$compile')(directive[0].outerHTML)(directive.scope());
    directive.replaceWith(content);
  });
});

export default () => ({
  restrict: 'E',
  replace: true,
  template: '<ul class="list-unstyled"></ul>',
  link(scope, element, { ids, kind }) {
    fetchListByIds(`/${kind}_sectors`, `${kind}_sectors`, ids.split(',')).then((sectors) =>
      element.html(
        sectors.map((sector) =>
          $('<li>').html(
            $('<a>')
              .attr({ href: Routes[`${kind}Sector`](sector), target: '_blank' })
              .text(sector.name)
          )
        )
      )
    );
  }
})
